<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_deliveryOrderPlanUpdate" />
      <!-- （画面名）搬入計画一覧-->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- ユーザー取引先-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userSuppliersSelected"
                :items="userSupplierList"
                :label="$t('label.lbl_userSupplier')"
                :hint="setSrhUserSupplier()"
                class="txt-single"
                clear-icon="mdi-close-circle"
                persistent-hint
                :rules="[rules.inputRequired]"
                pa-3
                :error-messages="alertSupplierMessage"
                disabled
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;&nbsp;</span>
            <!-- 納入先工場プルダウン-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userDeliveryDestination"
                :items="userDeliveryDestinationList"
                :label="$t('label.lbl_destinationyPlant')"
                :hint="setDestinationyPlant()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
                :disabled="sortingCheck || deleteFlg == '1' || achievementsFlg == '1'"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 搬入場所-->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="deliveryDestinationy"
                :label="$t('label.lbl_deliveryDestinationy')"
                class="txt-single"
                :rules="[rules.inputRequired]"
                clear-icon="mdi-close-circle"
                maxlength="10"
                clearable
                :disabled="sortingCheck || deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <span class="item-spacer require">*</span>
            <!-- 空白 -->
            <span class="item-spacer"></span>

            <!-- 計画コード-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="deliveryPlanCd"
                :label="$t('label.lbl_deliveryPlanCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="sortingCheck || deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;</span>

            <!-- 供給先コード-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="destinationCd"
                :label="$t('label.lbl_destinationCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="alignmentDisabled || deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
          </v-row>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="clientSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                @change="changeClient"
                :search-input.sync="searchClient"
                :rules="[rules.inputRequired]"
                :disabled="sortingCheck || deleteFlg == '1' || achievementsFlg == '1'"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- メーカー工場-->
            <div class="serch-textbox-useritemCd" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="7"
                v-model="makerFactory"
                :label="$t('label.lbl_makerFactory')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="sortingCheck || deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer"></span>
            <!--ボタン領域-->
            <div class="btn-sortSelection-area" :style="cargosStyle">
              <!-- 仕分情報選択ボタン-->
              <v-btn class="get-btn" v-on:click="getApiList">
                {{ $t("btn.btn_sortSelection") }}
              </v-btn>
            </div>
          </v-row>
          <v-row class="separator"></v-row>
          <v-row class="search-row">
            <div class="date-style1 first-search-item">
              <!-- 搬入日-->
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="deliveryDate"
                :label="$t('label.lbl_deliveryDate')"
                @change="changeDateDelivery"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              >
              </v-text-field>
            </div>

            <span class="item-spacer require">*</span>
            <div class="calender-btn-area">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuDelivery"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    x-small
                    :disabled="deleteFlg == '1' || achievementsFlg == '1'"
                  >
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryDateCal"
                  @input="dateMenuDelivery = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate"
                text
                x-small
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDate"
                text
                x-small
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 搬入時刻-->
            <div class="serch-textbox-locDetail">
              <v-autocomplete
                dense
                maxlength="2"
                v-model="deliveryTime"
                :items="deliveryTimeList"
                :label="$t('label.lbl_deliveryTime') + '(時)'"
                class="txt-single"
                :rules="[rules.inputRequired]"
                item-value="text"
                persistent-hint
                pa-3
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <div
              style="
                margin-top: 5px;
                margin-bottom: 35px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
              "
              :class="timeStyle"
            >
              ：
            </div>
            <div class="serch-textbox-locDetail">
              <v-autocomplete
                dense
                maxlength="2"
                v-model="deliveryMinutes"
                :items="deliveryMinutesList"
                :label="$t('label.lbl_deliveryTime') + '(分)'"
                class="txt-single"
                :rules="[rules.inputRequired]"
                item-value="text"
                persistent-hint
                pa-3
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 必須 -->
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷伝票NO-->
            <div class="textbox-arrivalNo" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="50"
                v-model="shipSlipNo"
                :label="$t('label.lbl_shipSlipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="alignmentDisabled || deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--差替区分-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 11rem">
              <v-autocomplete
                dense
                maxlength="1"
                v-model="replacementKbn"
                :items="replacementKbnList"
                :label="$t('label.lbl_replacementDiv')"
                item-value="text"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                pa-3
                :error-messages="timeAlertMessage"
                disabled
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <v-row class="separator"></v-row>
          <v-row class="search-row">
            <!-- ユーザー品番-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userProductCd"
                :items="userProductCdList"
                :label="$t('label.lbl_userProductCd')"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                pa-3
                @change="changeUserProductCd"
                :search-input.sync="searchUserProductCd"
                :hint="setSrhUserProductCd()"
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer"></span>

            <!-- 基本品番-->
            <div class="serch-textbox-useritemCd" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="baseProductCd"
                :label="$t('label.lbl_baseProductCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                disabled="disabled"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;</span>

            <!-- 付帯品番-->
            <div class="serch-textbox-useritemCd" style="float: left">
              <v-autocomplete
                dense
                maxlength="50"
                v-model="productAccessoryCd"
                :items="productAccessoryList"
                :label="$t('label.lbl_productAccessoryCd')"
                class="txt-single"
                item-value="text"
                persistent-hint
                pa-3
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;</span>
            <!-- 容器コード-->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="20"
                v-model="containerCode"
                :label="$t('label.lbl_containerCode')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="alignmentDisabled || deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
          </v-row>

          <v-row>
            <!-- ユーザー品番(QR)-->
            <div class="serch-textbox-useritemCd first-search-item" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="100"
                v-model="qrUserProductCd"
                :label="$t('label.lbl_userProductCd') + '(QR用)'"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;</span>

            <!-- 基本品番(QR)-->
            <div class="serch-textbox-useritemCd" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="100"
                v-model="qrProductCodeName"
                :label="$t('label.lbl_baseProductCd') + '(QR用)'"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;</span>

            <!-- 補足品番-->
            <div class="serch-textbox-useritemCd" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="100"
                v-model="amendmentProductCode"
                :label="$t('label.lbl_amendmentProductCode')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
          </v-row>
          <v-row class="separator"></v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 指示数-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="9"
                v-model="instNum"
                :label="$t('label.lbl_instNum')"
                class="input-number-modal"
                :rules="[rules.inputRequired, rules.isRangeCheck0]"
                @change="instCalculation()"
                @click="clickPop(instNum)"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <span class="item-spacer require">*</span>
            <!-- 空白 -->
            <span class="item-spacer"></span>

            <!-- 収容数-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="9"
                v-model="containerCapacityQuantity"
                :label="$t('label.lbl_containerCapacityQuantity')"
                class="input-number-modal"
                :rules="[rules.inputRequired, rules.isRangeCheck0]"
                clear-icon="mdi-close-circle"
                clearable
                @change="instCalculation()"
                @click="clickPop(containerCapacityQuantity)"
                :disabled="deleteFlg == '1' || achievementsFlg == '1'"
              />
            </div>
            <span class="item-spacer require">*</span>
            <!-- 空白 -->
            <span class="item-spacer"></span>

            <!-- ケース数荷姿-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="caseNumber"
                :label="$t('label.lbl_caseNumber')"
                class="input-number-modal"
                clear-icon="mdi-close-circle"
                clearable
                disabled="disabled"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;</span>

            <!-- 端数-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="fraction"
                :label="$t('label.lbl_fraction')"
                class="input-number-modal"
                clear-icon="mdi-close-circle"
                clearable
                disabled="disabled"
              />
            </div>
          </v-row>

          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn class="other-btn" v-on:click="backbtn">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>

              <div class="btn-post-area">
                <!--修正ボタン-->
                <v-btn
                  class="post-btn"
                  @click="openUpdateDelete()"
                  :disabled="deleteFlg == '1' || achievementsFlg == '1'"
                  >{{ $t("btn.btn_fix") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 仕分情報検索ポップアップ-->
      <v-dialog
        v-model="sortingInfodialogSearch"
        :max-width="2000"
        persistent
        no-click-animation
        @click:outside="ok"
      >
        <deliveryOrderPlanSortingInfoList
          id="selectionDialig"
          :isOpenDetailDialog.sync="sortingInfodialogSearch"
          :entity="sortingInfoSearchDialogData"
          class="overflow"
          v-on:sortingPopup="sortingPopup"
        />
      </v-dialog>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :deodpListFlg="infoDialog.deodpListFlg"
        :deodpAddFlg="infoDialog.deodpAddFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :deodpListPageParams="infoDialog.deodpListPageParams"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { getParameterProcurement } from "../../assets/scripts/js/GetParameterProcurement";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import deliveryOrderPlanSortingInfoList from "../procurement/deliveryOrderPlanSortingInfoList.vue";

export default {
  name: appConfig.MENU_ID.BIZ_DELIVERY_INSTRUCTIONS_SEARCH,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
    deliveryOrderPlanSortingInfoList,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !value ||
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isRangeCheck0: (value) => {
        if (value != null && value != undefined && value != "") {
          if (1 > Number(commonUtil.zen2han(value).replaceAll(",", "")))
            return i18n.tc("check.chk_inputMore");
        }
        return true;
      },
    },
    deliveryDate: "", //搬入日
    deliveryDateCal: "", //納入日カレンダー
    deliveryTime: "", //搬入時刻
    deliveryMinutes: "", //搬入時刻(分)
    shipSlipNo: "", //出荷伝票NO
    replacementKbn: "", //差替区分
    userSuppliersSelected: "", // ユーザー取引先
    userDeliveryDestination: "", //ユーザー納入先
    deliveryDestinationy: "", //搬入場所
    deliveryPlanCd: "", //計画コード
    clientSelected: "", // 取引先
    makerFactory: "", //メーカー工場
    destinationCd: "", //供給先コード
    userProductCd: "", //ユーザー品番
    itemCd: "",
    itemNm: "",
    baseProductCd: "", //基本品番
    productAccessoryCd: "", //付帯品番
    containerCapacityQuantity: "0", //収容数
    qrUserProductCd: "", //ユーザー品番(QR)
    qrProductCodeName: "", //基本品番(QR)
    amendmentProductCode: "", //補足品番
    containerCode: "", //容器コード
    instNum: "0", //指示数
    caseNumber: 0, //ケース数荷姿
    fraction: 0, //端数
    alignmentDisabled: false,
    dateMenuDelivery: false,
    deliveryMinutesList: [],
    deliveryTimeList: [],
    deleteFlg: "0",
    achievementsFlg: "0",
    updateDatetime: "",

    //パラメータ引き継ぎ用
    searchClient: "",
    searchDestination: "",
    searchUserProductCd: "",
    check1: false,
    check2: false,
    check3: false,
    sortingCheck: false,
    openMenu: null, // メニュー
    selectHintClient: "",
    userSuppliersSelectedHint: "",
    productHint: "",
    supplierList: [], //取引先リスト
    userSupplierList: [], //ユーザー取引先リスト
    userProductCdList: [], //ユーザー品番リスト
    replacementKbnList: [], //搬入時間リスト
    userDeliveryDestinationList: [], //ユーザー納入先リスト
    productAccessoryList: [],
    cargosStyle: "",
    code: "", //品番
    inputList: {},
    timeStyle: "",
    userClientCd: "",

    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
      deodpListFlg: false,
      deodpAddFlg: false,
      outsideClickNotCloseFlg: false,
    },
    sortingInfodialogSearch: false,
    // 詳細ポップアップ(仕分情報検索)
    sortingInfoSearchDialogData: {
      userSuppliersSelected: "",
      userSupplierList: [],
      clientSelected: "",
      supplierList: [],
      destinationyPlant: "",
      headerDeliveryDestinationy: "",
      headerDeliveryPlan: "",
      deliveryDate: "",
      deliveryDateCal: "",
      userMakerSected: "",
      userPlant: "",
      userDeliveryDestinationList: [],
      userDeliveryDestination: "",
      kbn: "02",
    },
    // 入力チェックエラーフラグ
    errorFlg: true,
    // バリデーションチェックメッセージダイアログ
    alertSupplierMessage: "",
    timeAlertMessage: "",

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
  }),
  created() {},

  methods: {
    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.userSupplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    getItemCd() {
      const fromList = getParameter.getUserItemsBizAddName(
        this.userSuppliersSelected,
        this.$route.params.searchuserProductCd
      );
      Promise.all([fromList])
        .then((result) => {
          // 画面の初期値を設定します。

          this.userProductCdList = result[0];
          this.userProductCd = this.userProductCdList[0].value;
          this.productHint = this.userProductCdList[0].text;
          this.itemCd = this.$route.params.searchuserProductCd;
          this.itemNm = this.userProductCdList[0].name;
          this.check3 = false;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getItemsubCd() {
      // 付帯品番のリストを取得
      const fromList = getParameter.getIncidentalItemsBizAddName(
        this.userSuppliersSelected,
        this.itemCd,
        this.baseProductCd
      );
      Promise.all([fromList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.productAccessoryList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 差替区分
      const replacementKbnList = getParameter.getCodeMst(appConfig.CODETYPE.REPLACEMENT_KBN);
      //搬入時刻(時)
      const deliveryTimelist = getParameter.getCodeMst(appConfig.CODETYPE.USER_DELIVERY_TIME);

      return Promise.all([replacementKbnList, deliveryTimelist])
        .then((result) => {
          this.replacementKbnList = result[0];
          this.deliveryTimeList = result[1];
          var count = result[1][this.deliveryTimeList.length - 1].value;
          for (var i = 0; i < 23; i++) {
            this.deliveryTimeList.push({
              text: String(Number(count) + Number(i)),
              value: String(Number(count) + Number(i)),
            });
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 搬入先工場リスト取得
     */
    getDeliveryComp() {
      // ユーザー取引先が存在する場合
      if (this.userSuppliersSelected) {
        for (let i = 0; i < this.userSupplierList.length; i++) {
          if (this.userSupplierList[i].value == this.userSuppliersSelected) {
            this.userClientCd = this.userSupplierList[i].clientCd;
          }
        }
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const userDeliveryDestinationList = getParameterProcurement.getBizCarryCompCd(
          this.userClientCd,
          dateTimeHelper.convertUTC(this.deliveryDate)
        );
        return Promise.all([userDeliveryDestinationList])
          .then((result) => {
            // 画面の初期値を設定します。
            this.userDeliveryDestinationList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }
    },

    /**
     * プルダウンチェンジイベント
     */
    changeClient(value) {
      // 取引先を選択時
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.selectHintClient = val.text;
        this.searchClient = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.selectHintClient = "";
      } else {
        // 処理なし
      }
    },

    changeUserProductCd(value) {
      // 既存のユーザー品番/品名を選択時
      let val = this.userProductCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.searchUserProductCd = val.text;
      }
      this.userProductCdList = this.userProductCdList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHint = "";
        this.productAccessoryCd = "";
        this.baseProductCd = "";
        this.code = "";
        this.productAccessoryList = [];
        this.qrUserProductCd = "";
        this.qrProductCodeName = "";
      } else {
        this.loadingCounter = 1;
        // 付帯品番のリストを取得
        this.productAccessoryCd = "";
        for (var i = 0; i < this.userProductCdList.length; i++) {
          if (this.userProductCdList[i].value == this.userProductCd) {
            this.code = this.userProductCdList[i].code;
            this.baseProductCd = this.userProductCdList[i].baseCd;
            break;
          }
        }
        const fromList = getParameter.getIncidentalItemsBizAddName(
          this.userSuppliersSelected,
          this.code,
          this.baseProductCd
        );
        Promise.all([fromList])
          .then((result) => {
            // 画面の初期値を設定します。
            this.productAccessoryList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }
    },
    /**
     * 納入指示一覧画面へ渡す値を設定
     */
    backbtn() {
      this.backFlg = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
      this.updateDialogMessage = true;
    },

    /**
     * 修正ボタン押下時
     */
    openUpdateDelete() {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        //削除フラグチェック
        if (this.deleteFlg == "1") {
          this.backFlg = false;
          this.updateDialogMessage = true;
          this.errorFlg = false;
          this.backMessage = messsageUtil.getMessage("P-SUP-003_002_E");
          return;
        }
        //実績フラグチェック
        else if (this.achievementsFlg == "1") {
          this.backFlg = false;
          this.updateDialogMessage = true;
          this.errorFlg = false;
          this.backMessage = messsageUtil.getMessage("P-SUP-003_001_E");
          return;
        } else {
          this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.confirmDialog.isOpen = true;
          this.confirmDialog.screenFlag = true;
          this.confirmDialog.changeFlag = false;
          this.confirmDialog.okAction = this.updateOrder;
        }
      }
    },

    /**
     * 修正(POST)
     */
    updateOrder() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_003;
      // 差替区分
      body.reqIdv.replacementDiv = this.$route.params.replacementDiv;
      //出荷伝票明細SID
      body.reqIdv.outListSubSid = this.inputList.outListSubSid;
      //出荷伝票No.(更新前)
      body.reqIdv.outListNoOld = this.inputList.outListNo;
      //ユーザー納入先(更新前)
      body.reqIdv.userDestinationSidOld = this.$route.params.userClientSid;
      //取引先(更新前)
      body.reqIdv.makerClientSidOld = this.selection.makerClientSid;
      //収容数
      body.reqIdv.containerCapacityQuantity = String(this.containerCapacityQuantity).replace(
        /[^0-9]/gi,
        ""
      );
      //指示数
      body.reqIdv.stock = String(this.instNum).replace(/[^0-9]/gi, "");
      //ケース数荷姿
      body.reqIdv.caseQuantity = String(this.caseNumber).replace(/[^0-9]/gi, "");
      //端数
      body.reqIdv.fracQuantity = String(this.fraction).replace(/[^0-9]/gi, "");
      //登録区分
      body.reqIdv.entryDiv = this.inputList.entryDiv;
      //更新日時
      body.reqIdv.updateDatetime = this.inputList.updateDatetime;
      //登録区分が「手入力」以外の場合
      if (this.inputList.entryDiv != "03") {
        //出荷伝票No.
        body.reqIdv.outListNo = this.inputList.outListNo;
        //ユーザー取引先
        body.reqIdv.userClientSid = this.$route.params.searchuserSuppliersSelected;
        //ユーザー納入先
        body.reqIdv.userDestinationSid = this.$route.params.userClientSid;
        //取引先
        body.reqIdv.makerClientSid = this.selection.makerClientSid;
        //搬入場所
        body.reqIdv.deliveryPlaceCd = this.selection.deliveryPlaceCd;
        //ユーザー品番
        body.reqIdv.userItemCd = this.itemCd;
        //ユーザー品名
        body.reqIdv.userItemNm = this.itemNm;
        //基本品番
        body.reqIdv.userItemBaseCd = this.baseProductCd;
        //付帯品番
        body.reqIdv.userItemSubCd = this.productAccessoryCd;
        //ユーザー品番(QR)
        if (this.qrUserProductCd) {
          body.reqIdv.userItemCdQr = this.qrUserProductCd.replaceAll("-", "");
        } else {
          body.reqIdv.userItemCdQr = this.itemCd.replaceAll("-", "");
        }
        //基本品番(QR)
        if (this.qrProductCodeName) {
          body.reqIdv.userItemBaseCdQr = this.qrProductCodeName.replaceAll("-", "");
        } else {
          body.reqIdv.userItemBaseCdQr = this.baseProductCd.replaceAll("-", "");
        }
        //補足品番
        body.reqIdv.userItemAdditionalCd = this.amendmentProductCode;
        //容器コード
        body.reqIdv.containerCd = this.selection.containerCd;
        //搬入日付
        body.reqIdv.deliveryDate = this.deliveryDate.replaceAll("/", "");
        //搬入時刻
        body.reqIdv.deliveryTime = this.deliveryTime + this.deliveryMinutes;
      }
      //登録区分が「手入力」の場合
      else {
        // ユーザー納入先
        let userDeliveryDestination = this.userDeliveryDestinationList.find(
          (v) => v.value == this.userDeliveryDestination
        ).userClientSid;
        //出荷伝票No.
        if (this.shipSlipNo) {
          body.reqIdv.outListNo = this.shipSlipNo;
        } else {
          body.reqIdv.outListNo = this.inputList.outListNo;
        }
        //ユーザー取引先
        body.reqIdv.userClientSid = this.userSuppliersSelected;
        //ユーザー納入先
        body.reqIdv.userDestinationSid = userDeliveryDestination;
        //取引先
        body.reqIdv.makerClientSid = this.clientSelected;
        //メーカー工場
        body.reqIdv.clientPlantCd = this.makerFactory;
        //搬入場所
        body.reqIdv.deliveryPlaceCd = this.deliveryDestinationy;
        //供給先コード
        body.reqIdv.supplyToCd = this.destinationCd;
        //計画コード
        body.reqIdv.planCd = this.deliveryPlanCd;
        //ユーザー品番
        body.reqIdv.userItemCd = this.itemCd;
        //ユーザー品名
        body.reqIdv.userItemNm = this.itemNm;
        //基本品番
        body.reqIdv.userItemBaseCd = this.baseProductCd;
        //付帯品番
        body.reqIdv.userItemSubCd = this.productAccessoryCd;
        //ユーザー品番(QR)
        if (this.qrUserProductCd) {
          body.reqIdv.userItemCdQr = this.qrUserProductCd.replaceAll("-", "");
        } else {
          body.reqIdv.userItemCdQr = this.itemCd.replaceAll("-", "");
        }
        //基本品番(QR)
        if (this.qrProductCodeName) {
          body.reqIdv.userItemBaseCdQr = this.qrProductCodeName.replaceAll("-", "");
        } else {
          body.reqIdv.userItemBaseCdQr = this.baseProductCd.replaceAll("-", "");
        }
        //補足品番
        body.reqIdv.userItemAdditionalCd = this.amendmentProductCode;
        //容器コード
        body.reqIdv.containerCd = this.containerCode;
        //搬入日付
        body.reqIdv.deliveryDate = this.deliveryDate.replaceAll("/", "");
        //搬入時刻
        body.reqIdv.deliveryTime = this.deliveryTime + this.deliveryMinutes;
      }
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DELIVERY_UPDATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addRcvSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.deodpListFlg = true;
              this.infoDialog.deodpAddFlg = false;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.deodpListPageParams = {
                searchSuppliersSelected: this.searchSuppliersSelected,
                transitionFlg: true,
                searchuserMakerSected: this.$route.params.searchuserMakerSected,
                searchimportStatus: this.$route.params.searchimportStatus,
                searchuserSuppliersSelected: this.$route.params.searchuserSuppliersSelected,
                searchClientCd: this.$route.params.searchClientCd,
                searchMakerClientCd: this.$route.params.searchMakerClientCd,
                searchselectHintClient: this.$route.params.searchselectHintClient,
                searchsupplierList: this.$route.params.searchsupplierList,
                searchSuppliersList: this.$route.params.searchSuppliersList,
                searchclientSelected: this.$route.params.searchclientSelected,
                searchdestinationyPlant: this.$route.params.searchdestinationyPlant,
                searchheaderDeliveryDestinationy:
                  this.$route.params.searchheaderDeliveryDestinationy,
                searchheaderDeliveryPlan: this.$route.params.searchheaderDeliveryPlan,
                searchdeliveryDate: this.$route.params.searchdeliveryDate,
                searchdeliveryDateCal: this.$route.params.searchdeliveryDateCal,
                searchdeliveryTimeFrom: this.$route.params.searchdeliveryTimeFrom,
                searchdeliveryTimeTo: this.$route.params.searchdeliveryTimeTo,
                searchshipSlipNo: this.$route.params.searchshipSlipNo,
                searchdataIssueDateFrom: this.$route.params.searchdataIssueDateFrom,
                searchdataIssueDateFromCal: this.$route.params.searchdataIssueDateFromCal,
                searchdataIssueDateTo: this.$route.params.searchdataIssueDateTo,
                searchdataIssueDateToCal: this.$route.params.searchdataIssueDateToCal,
                searchIssuedDiff: this.$route.params.searchIssuedDiff,
                searchuserItemCd: this.$route.params.searchuserItemCd,
                searchproductHint: this.$route.params.searchproductHint,
                searchuserProductCdList: this.$route.params.searchuserProductCdList,
                searchuserProductCd: this.$route.params.searchuserProductCd,
                searchuserProductSid: this.$route.params.searchuserProductSid,
                searchautoUpdateFlag: this.$route.params.searchautoUpdateFlag,
                searchautoUpdateIntervalMin: this.$route.params.searchautoUpdateIntervalMin,
                searchInputListFlag: this.$route.params.searchInputListFlag,
                searchItemsPerPageBtn: this.$route.params.searchItemsPerPageBtn,
                searchDestinationyPlantList: this.$route.params.searchDestinationyPlantList,
                btnid: this.$route.params.btnid,
                searchDeliveryPrintDate: this.$route.params.searchDeliveryPrintDate,
                searchActualTicketPrintDate: this.$route.params.searchActualTicketPrintDate,
                // 画面遷移フラグ
                checkFlg: this.checkFlg,
              };
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },

    /**
     * カンマ区切り対応
     */
    instCalculation() {
      var detail = commonFunction.getDelimiter(this.instNum, 7);
      if (detail) {
        detail = detail.replace(/[^0-9]/gi, "");
      } else {
        detail = "0";
      }
      var detail1 = commonFunction.getDelimiter(this.containerCapacityQuantity, 7);
      if (detail1) {
        detail1 = detail1.replace(/[^0-9]/gi, "");
      } else {
        detail1 = "0";
      }
      this.fraction = Number(detail) % Number(detail1);
      this.caseNumber = Math.floor(Number(detail) / Number(detail1));
      if (this.fraction != 0) {
        this.caseNumber = Number(this.caseNumber) + 1;
        this.fraction = commonFunction.getDelimiter(this.fraction, 7);
        if (!this.fraction) {
          this.fraction = 0;
        }
      }
      this.instNum = commonFunction.getDelimiter(this.instNum, 7);
      this.containerCapacityQuantity = commonFunction.getDelimiter(
        this.containerCapacityQuantity,
        7
      );
      this.caseNumber = commonFunction.getDelimiter(this.caseNumber, 7);
    },

    /**
     * 戻るボタンダイアログ画面、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
      if (this.backFlg) {
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_LIST,
          params: {
            // officeSid: this.user
            searchSuppliersSelected: this.searchSuppliersSelected,
            transitionFlg: true,
            searchuserMakerSected: this.$route.params.searchuserMakerSected,
            searchimportStatus: this.$route.params.searchimportStatus,
            searchuserSuppliersSelected: this.$route.params.searchuserSuppliersSelected,

            searchClientCd: this.$route.params.searchClientCd,
            searchMakerClientCd: this.$route.params.searchMakerClientCd,
            searchselectHintClient: this.$route.params.searchselectHintClient,
            searchsupplierList: this.$route.params.searchsupplierList,
            searchSuppliersList: this.$route.params.searchSuppliersList,
            searchclientSelected: this.$route.params.searchclientSelected,

            searchdestinationyPlant: this.$route.params.searchdestinationyPlant,
            searchheaderDeliveryDestinationy: this.$route.params.searchheaderDeliveryDestinationy,
            searchheaderDeliveryPlan: this.$route.params.searchheaderDeliveryPlan,
            searchdeliveryDate: this.$route.params.searchdeliveryDate,
            searchdeliveryDateCal: this.$route.params.searchdeliveryDateCal,
            searchdeliveryTimeFrom: this.$route.params.searchdeliveryTimeFrom,
            searchdeliveryTimeTo: this.$route.params.searchdeliveryTimeTo,
            searchshipSlipNo: this.$route.params.searchshipSlipNo,
            searchdataIssueDateFrom: this.$route.params.searchdataIssueDateFrom,
            searchdataIssueDateFromCal: this.$route.params.searchdataIssueDateFromCal,
            searchdataIssueDateTo: this.$route.params.searchdataIssueDateTo,
            searchdataIssueDateToCal: this.$route.params.searchdataIssueDateToCal,
            searchIssuedDiff: this.$route.params.searchIssuedDiff,
            searchuserItemCd: this.$route.params.searchuserItemCd,
            searchproductHint: this.$route.params.searchproductHint,
            searchuserProductCdList: this.$route.params.searchuserProductCdList,
            searchuserProductCd: this.$route.params.searchuserProductCd,
            searchuserProductSid: this.$route.params.searchuserProductSid,
            searchautoUpdateFlag: this.$route.params.searchautoUpdateFlag,
            searchautoUpdateIntervalMin: this.$route.params.searchautoUpdateIntervalMin,
            searchInputListFlag: this.$route.params.searchInputListFlag,
            searchItemsPerPageBtn: this.$route.params.searchItemsPerPageBtn,
            searchDestinationyPlantList: this.$route.params.searchDestinationyPlantList,
            btnid: this.$route.params.btnid,
            searchDeliveryPrintDate: this.$route.params.searchDeliveryPrintDate,
            searchActualTicketPrintDate: this.$route.params.searchActualTicketPrintDate,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
          },
        });
      }
    },
    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 初期値
     */
    init() {
      /* ---------搬入指示データ表示-------------- */
      //ユーザー取引先
      this.userSuppliersSelectedHint = this.$route.params.searchuserSuppliersSelected;
      this.userSuppliersSelected = this.$route.params.searchuserSuppliersSelected;
      //差替区分
      this.replacementKbn = this.$route.params.replacementDiv;
      //搬入場所
      this.deliveryDestinationy = this.$route.params.searchheaderDeliveryDestinationyRow;
      //計画コード
      this.deliveryPlanCd = this.$route.params.searchheaderDeliveryPlanRow;
      //搬入日
      this.deliveryDate = this.$route.params.searchdeliveryDate;
      //搬入日(カレンダー用)
      this.deliveryDateCal = this.$route.params.searchdeliveryDateCal;
      //出荷伝票No.
      this.shipSlipNo = this.$route.params.searchshipSlipNoRow;
      //搬入時間(時)
      this.deliveryTime = this.$route.params.searchDeliveryTime.substring(0, 2);
      //搬入時間(分)
      this.deliveryMinutes = this.$route.params.searchDeliveryTime.slice(-2);
      //指示数
      this.instNum = new String(this.$route.params.searchInstNum);
      this.getDeliveryData();
      //ユーザ品番List取得
      this.getItemCd();
      const codeList = this.getMstCode();
      //時間セット
      for (var i = 0; i < 60; i++) {
        var time = ("0" + i).slice(-2);
        this.deliveryMinutesList.push({
          text: time,
          value: time,
          name: time,
        });
      }
      Promise.all([codeList])
        .then(() => {
          // 画面の初期値を設定します。
          this.getCustomInfo();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 搬入計画データ取得(GET)
     */
    getDeliveryData() {
      const config = this.$httpClient.createGetApiRequestConfig();
      // ローディング画面表示ON
      this.loadingCounter = 1;
      //user取引先SID
      config.params.userClientSid = this.$route.params.userClientSid;
      //出荷伝票No.
      config.params.outListNo = this.$route.params.searchshipSlipNoRow;
      //差替区分
      config.params.replacementDiv = this.$route.params.replacementDiv;
      //ユーザー取引先SID
      config.params.deliverySid = this.$route.params.searchuserSuppliersSelected;
      //APIに渡す値をconfig.paramsにセット==================================//
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_DELIVERY_DETAIL_ACQUISITION, config)
          .then((response) => {
            // console.debug("apigetStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              //搬入情報をセット
              this.selection = jsonData.resIdv.deliveryPlanInformation;
              this.inputList = jsonData.resIdv.deliveryPlanInformation;
              //ユーザー納入先
              this.selectHintDestination =
                `${this.selection.userClientCd}` + `（${this.selection.userClientNm}）`;
              this.userDeliveryDestination = this.selection.userClientCd;
              if (this.$route.params.searchDestinationyPlantList) {
                //ユーザー納入先リストセット
                this.userDeliveryDestinationList = this.$route.params.searchDestinationyPlantList;
              }

              //搬入場所
              this.deliveryDestinationy = this.selection.deliveryPlaceCd;
              //計画コード
              this.deliveryPlanCd = this.selection.planCd;
              //供給先コード
              this.destinationCd = this.selection.supplyToCd;
              //取引先
              this.selectHintClient =
                `${this.selection.makerClientCd}` + `（${this.selection.makerClientNm}）`;
              this.clientSelected = this.selection.makerClientSid;
              //取引先リストセット
              this.supplierList.push({
                text: `${this.selection.makerClientCd}` + `（${this.selection.makerClientNm}）`,
                value: this.selection.makerClientSid,
                name: this.selection.makerClientNm,
              });
              //メーカー工場
              this.makerFactory = this.selection.clientPlantCd;
              //ユーザー品番(QR)
              this.qrUserProductCd = this.selection.userItemCdQr.trim();
              //基本品番(QR)
              this.qrProductCodeName = this.selection.userItemNameQr.trim();
              //収容数
              this.containerCapacityQuantity = new String(this.selection.containerCapacityQuantity);
              //ユーザー品番
              this.itemCd = this.selection.userItemCd.trim();
              //基本品番
              this.baseProductCd = this.selection.userItemBaseCd.trim();
              //付帯品番
              this.productAccessoryCd = this.selection.userItemSubCd.trim();
              //補足品番
              this.amendmentProductCode = this.selection.userItemAdditionalCd.trim();
              //容器コード
              this.containerCode = this.selection.containerCd;
              //削除フラグ
              this.deleteFlg = this.selection.deleteFlg;
              this.achievementsFlg = this.selection.achievementsFlg;
              if (this.selection.deleteFlg == "1" || this.selection.achievementsFlg == "1") {
                this.cargosStyle = "display: none";
                this.timeStyle = "time-class";
              }
              //更新日時
              this.updateDatetime = this.selection.updateDatetime;
              if (this.selection.entryDiv != "03") {
                this.alignmentDisabled = true;
                this.sortingCheck = true;
                this.cargosStyle = "display: none";
                this.timeStyle = "time-class";
              }
              //数量形式(3桁カンマ区切り対応)
              this.instCalculation();
              this.getItemsubCd();
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetStock() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 搬入日を－１日します。
     */
    prevDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "");
    },
    /**
     * 搬入日を＋１日します。
     */
    nextDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "add");
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(搬入日)
     */
    changeDateDelivery(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.deliveryDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.deliveryDateCal = null;
      }
    },

    /**
     * ユーザー取引先コードリストボックス取得処理
     */
    setSrhUserSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          return this.userSupplierList[i].name;
        }
      }
    },
    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.clientSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    /**
     * ユーザー品番コードリストボックス取得処理
     */
    setSrhUserProductCd() {
      for (var i = 0; i < this.userProductCdList.length; i++) {
        if (this.userProductCdList[i].value == this.userProductCd) {
          this.baseProductCd = this.userProductCdList[i].baseCd;
          this.itemNm = this.userProductCdList[i].name;
          this.itemCd = this.userProductCdList[i].code;
          return this.userProductCdList[i].name;
        }
      }
    },

    /**
     * 納入先工場コードリストボックス取得処理
     */
    setDestinationyPlant() {
      for (var i = 0; i < this.userDeliveryDestinationList.length; i++) {
        if (this.userDeliveryDestinationList[i].value == this.userDeliveryDestination) {
          return this.userDeliveryDestinationList[i].name;
        }
      }
    },

    /**
     * 仕分情報選択ボタン押下時
     */
    getApiList() {
      this.sortingInfodialogSearch = true;
      this.sortingInfoSearchDialogData.userSupplierList = this.userSupplierList;
      this.sortingInfoSearchDialogData.userSuppliersSelected = this.userSuppliersSelected;
      this.sortingInfoSearchDialogData.deliveryDate = this.deliveryDate;
      this.sortingInfoSearchDialogData.deliveryDateCal = this.deliveryDateCal;
      this.sortingInfoSearchDialogData.headerDeliveryPlan = this.deliveryPlanCd;
      this.sortingInfoSearchDialogData.headerDeliveryDestinationy = this.deliveryDestinationy;
      this.sortingInfoSearchDialogData.makerFactory = this.makerFactory;
      if (this.clientSelected != "" && this.clientSelected != null) {
        this.sortingInfoSearchDialogData.clientSelected = this.clientSelected;
        this.sortingInfoSearchDialogData.supplierList = this.supplierList;
        this.sortingInfoSearchDialogData.userMakerSected = "02";
      } else {
        this.sortingInfoSearchDialogData.clientSelected = "";
        this.sortingInfoSearchDialogData.supplierList = [];
        this.sortingInfoSearchDialogData.userMakerSected = "01";
      }
    },

    sortingPopup() {
      this.deliveryPlanCd = this.sortingInfoSearchDialogData.headerDeliveryPlan;
      this.deliveryDestinationy = this.sortingInfoSearchDialogData.headerDeliveryDestinationy;
      this.userPlant = this.sortingInfoSearchDialogData.userPlant;
      this.userSupplierList = this.sortingInfoSearchDialogData.userSupplierList;
      this.userSuppliersSelected = this.sortingInfoSearchDialogData.userSuppliersSelected;
      if (this.sortingInfoSearchDialogData.userDeliveryDestination != "") {
        this.check1 = false;
      }
      this.userDeliveryDestination = this.sortingInfoSearchDialogData.userDeliveryDestination;
      this.userDeliveryDestinationList =
        this.sortingInfoSearchDialogData.userDeliveryDestinationList;
      if (this.sortingInfoSearchDialogData.clientSelected != "") {
        this.check2 = false;
      }
      this.clientSelected = this.sortingInfoSearchDialogData.clientSelected;
      this.supplierList = this.sortingInfoSearchDialogData.supplierList;
      this.makerFactory = this.sortingInfoSearchDialogData.userPlant;
      // this.sortingCheck = true;
      // this.cargosStyle = "display: none";
      // this.sortingCheckRow = "margin-top: 25px";
    },
    ok() {
      //ダイアログの枠外クリック時
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
  },

  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    // 取引先取得処理
    searchClient(val) {
      if (!this.check2) {
        if (val == null) {
          this.check2 = true;
        }
        return;
      }
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.selectHintClient) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得

          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.CLIENT,
            dateTimeHelper.convertUTC(this.deliveryDate),
            undefined,
            undefined,
            val,
            sessionStorage.getItem("sales_office_sid")
          );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.supplierList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.supplierList.length = 0;
              this.supplierList = [];
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.supplierList.length = 0;
          this.supplierList = [];
        }
      }
    },

    // ユーザー品番取得処理
    searchUserProductCd(val) {
      if (!this.check3) {
        if (val == null) {
          this.check3 = true;
        }
        return;
      }
      if (val ?? false) {
        // ユーザー品番が選択されてない場合、処理しない
        if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // 選択した会社コードを取得

          const fromList = getParameter.getUserItemsBizAddName(this.userSuppliersSelected, val);
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.userProductCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.userProductCdList.length = 0;
              this.userProductCdList = [];
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.userProductCdList.length = 0;
          this.userProductCdList = [];
        }
      }
    },

    dialog(val) {
      val || this.close();
    },
    deliveryDateCal(val) {
      this.deliveryDate = this.formatDate(val);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.textbox {
  width: 11.2rem;
}
.btn-sortSelection-area {
  float: left;
  margin-bottom: 1.8rem;
}
.btn-post-area {
  position: relative;
  left: 63.3rem;
}
.separator {
  padding-top: 1.5rem;
  /* border-top: 1px solid rgba(0, 0, 0, 0.42); */
  margin-top: 1.5rem;
}
.serch-textbox-locDetail {
  width: 8.7rem;
}
.date-style1 {
  width: 11rem;
}

::v-deep .v-messages__message {
  white-space: nowrap;
}
::v-deep .time-class {
  border-image: repeating-linear-gradient(
      to right,
      rgba(0, 0, 0, 0.38) 0px,
      rgba(0, 0, 0, 0.38) 2px,
      transparent 2px,
      transparent 4px
    )
    1 repeat;
}
</style>
