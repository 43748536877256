import { render, staticRenderFns } from "./ManageDataImport.vue?vue&type=template&id=2d00c442&scoped=true"
import script from "./ManageDataImport.vue?vue&type=script&lang=js"
export * from "./ManageDataImport.vue?vue&type=script&lang=js"
import style0 from "./ManageDataImport.vue?vue&type=style&index=0&id=2d00c442&prod&lang=css"
import style1 from "./ManageDataImport.vue?vue&type=style&index=1&id=2d00c442&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d00c442",
  null
  
)

export default component.exports